import React, { useContext, useEffect, useState } from "react";
import LoadingAction from "../../../../themes/LoadingAction/LoadingAction";
import "./AdminPagamentosSearch.css";
import { Button, ConfigProvider } from "antd";
import { AuthContext } from "../../../../contexts/AuthContext";
import { Link, useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { useParams } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { DatePicker } from "antd";
import "antd/dist/antd.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import * as links from "../../../../utils/links";
import {
  AiOutlineEdit,
  AiFillDelete,
  AiFillDollarCircle,
} from "react-icons/ai";
import qr_code_icon from "../../../../assets/images/QR.png";
import notes from "../../../../assets/images/notes.png";
import {
  Flex,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  Tab,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Table,
  useMediaQuery,
} from "@chakra-ui/react";
import { format } from "date-fns";
import ptBR from "antd/lib/locale/pt_BR";

const AdminPagamentosSearch = (props) => {
  const location = useLocation();
  const { maquinaInfos, clienteInfo } = location.state;
  const { setDataUser, authInfo, setNotiMessage } = useContext(AuthContext);

  let navigate = useNavigate();
  const token = authInfo?.dataUser?.token;
  const { id } = useParams();
  const { RangePicker } = DatePicker;

  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [listCanals, setListCanals] = useState([]);
  const [estornos, setEstornos] = useState("");
  const [estoque, setEstoque] = useState("");
  const [cash, setCash] = useState("");
  const [total, setTotal] = useState("");
  const [loadingTable, setLoadingTable] = useState(false);
  const [dataInicio, setDataInicio] = useState(null);
  const [dataFim, setDataFim] = useState(null);
  const [pagamentos, setPagamentos] = useState({});
  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");

  console.log("pagamentos", pagamentos);

  useEffect(() => {
    if (id.trim() !== "") {
      getData(id);
    }
  }, [id]);

  useEffect(() => {
    if (dataFim) {
      getPaymentsPeriod(dataInicio, dataFim);
    }
  }, [dataFim]);

  const getData = async (id) => {
    setLoadingTable(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVIDOR}/pagamentos-adm/${id}`,
        {
          headers: {
            "x-access-token": token,
            "content-type": "application/json",
          },
        }
      );
      setLoadingTable(false);
      if (res.status === 200) {
        setEstornos(res.data.estornos);
        setCash(res.data.cash);
        setEstoque(res.data.estoque);
        setTotal(res.data.total);
        setListCanals(res.data.pagamentos || []);
      }
    } catch (err) {
      setLoadingTable(false);
      if ([401, 403].includes(err.response?.status)) {
        setNotiMessage({
          type: "error",
          message: "A sua sessão expirou, para continuar faça login novamente.",
        });
        setDataUser(null);
      }
    }
  };

  const getPaymentsPeriod = async (dataInicio, dataFim) => {
    setLoadingTable(true);
    const url = `${process.env.REACT_APP_SERVIDOR}/pagamentos-periodo-adm/${id}`;
    try {
      const res = await axios.post(
        url,
        {
          dataInicio: `${dataInicio}T00:00:00.000Z`,
          dataFim: `${dataFim}T23:59:00.000Z`,
        },
        {
          headers: {
            "x-access-token": token,
            "content-type": "application/json",
          },
        }
      );
      setLoadingTable(false);
      if (res.status === 200) {
        setEstornos(res.data.estornos);
        setCash(res.data.cash);
        setTotal(res.data.total);
        setListCanals(res.data.pagamentos || []);
      }
    } catch (err) {
      setLoadingTable(false);
      if ([401, 403].includes(err.response?.status)) {
        setNotiMessage({
          type: "error",
          message: "A sua sessão expirou, para continuar faça login novamente.",
        });
        setDataUser(null);
      }
    }
  };

  const pagamentoInicial = new Date(listCanals[0]?.data || Date.now());
  const pagamentoFinal = new Date(
    listCanals[listCanals.length - 1]?.data || Date.now()
  );

  pagamentoInicial.setHours(0, 0, 0, 0);
  pagamentoFinal.setHours(23, 59, 0, 0);

  useEffect(() => {
    if (
      maquinaInfos?.id &&
      isValidDate(pagamentoInicial) &&
      isValidDate(pagamentoFinal)
    ) {
      axios
        .post(
          `${process.env.REACT_APP_SERVIDOR}/relatorio-03-pagamentos-adm`,
          {
            dataFim: pagamentoFinal.toISOString(),
            dataInicio: pagamentoInicial.toISOString(),
            maquinaId: maquinaInfos.id,
          },
          {
            headers: {
              "x-access-token": token,
              "content-type": "application/json",
            },
          }
        )
        .then((response) => setPagamentos(response.data));
    }
  }, [maquinaInfos]);

  const onRelatorioHandler = () => {
    if (!dataInicio || !dataFim) {
      setNotiMessage({
        type: "error",
        message:
          "Selecione no calendário a data de início e fim para gerar o relatório!",
      });
    } else {
      navigate(`${links.RELATORIO_ADMIN}/${id}`, {
        state: {
          maquinaInfos,
          clienteInfo,
          dataInicio,
          dataFim,
          totalVendas: pagamentosRecebidos.length,
        },
      });
    }
  };

  const pagamentosEstornados = listCanals.filter(({ estornado }) => estornado);
  const pagamentosRecebidos = listCanals.filter(({ estornado }) => !estornado);

  useEffect(() => {
    const calcularPagamentos = () => {
      const pagamentosCredito = calcularTotalPorTipo("credit_card");
      const pagamentosDebito = calcularTotalPorTipo("debit_card");
      const pagamentosPix = listCanals
        .filter(
          (item) =>
            item.tipo === "bank_transfer" || item.tipo === "account_money"
        )
        .reduce((acc, curr) => acc + Number(curr.valor), 0);

      setPagamentos((prev) => ({
        ...prev,
        credito: pagamentosCredito,
        debito: pagamentosDebito,
        pix: pagamentosPix,
      }));
    };

    const calcularTotalPorTipo = (tipo) => {
      return listCanals
        .filter((item) => item.tipo === tipo)
        .reduce((acc, curr) => acc + Number(curr.valor), 0);
    };

    calcularPagamentos();
  }, [listCanals, pagamentos]);

  function isValidDate(value) {
    const date = new Date(value);
    return date instanceof Date && !isNaN(date.getTime());
  }

  const columns = [
    {
      title: "Data",
      dataIndex: "data",
      key: "data",
      width: 500,
      render: (data) => (
        <span>{moment(data).format("DD/MM/YYYY HH:mm:ss")}</span>
      ),
    },
    {
      title: "Forma de pagamento",
      dataIndex: "tipo",
      key: "tipo",
      render: (tipo) => (
        <span>
          {tipo === "bank_transfer"
            ? "PIX"
            : tipo === "CASH"
            ? "Espécie"
            : tipo === "debit_card"
            ? "Débito"
            : tipo === "credit_card"
            ? "Crédito"
            : tipo === "account_money"
            ? "PIX"
            : "-"}
        </span>
      ),
    },
    {
      title: "Valor",
      dataIndex: "valor",
      key: "valor",
      render: (valor) =>
        new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(valor),
    },
    {
      title: "Identificador MP",
      dataIndex: "mercadoPagoId",
      key: "mercadoPagoId",
    },
    {
      title: "Estornado",
      dataIndex: "estornado",
      key: "estornado",
      width: 100,
      render: (estornado, record) => (
        <OverlayTrigger
          key={record.key}
          placement="top"
          overlay={
            <Tooltip id={`tooltip-top-${record.key}`}>
              {record.motivoEstorno || "Sem motivo registrado"}
            </Tooltip>
          }
        >
          <span
            style={{ color: estornado ? "gray" : "green", cursor: "pointer" }}
          >
            {estornado ? "Estornado" : "Recebido"}
          </span>
        </OverlayTrigger>
      ),
    },
  ];

  return (
    <div
      className="Admin_PagamentosSearch_container"
      style={{ minHeight: "100vh" }}
    >
      {isLoading && <LoadingAction />}
      <Flex width="100%" justifyContent="flex-end">
        <Button
          className="Admin_PagamentosSearch_header_back"
          onClick={() => navigate(-1)}
        >
          VOLTAR
        </Button>
      </Flex>
      <div className="Admin_PagamentosSearch_header">
        <Flex
          alignItems={isLargerThan800 ? "center" : "flex-start"}
          gap="20px"
          flexDirection={isLargerThan800 ? "row" : "column"}
          width="100%"
        >
          <div
            className="Admin_Dashboard_staBlockTitle"
            style={{ color: "white" }}
          >
            {maquinaInfos?.nome}
          </div>

          <Button
            className="Admin_PagamentosSearch_header_editBtn"
            onClick={() => {
              navigate(`${links.CLIENTES_MAQUINA_DELETE_PAGAMENTOS}/${id}`, {
                state: location.state,
              });
            }}
          >
            <AiFillDelete />
            <span>Excluir Pagamentos</span>
          </Button>

          <div className="Admin_PagamentosSearch_datePicker">
            <FontAwesomeIcon
              style={{ marginBottom: "10px", marginRight: "10px" }}
              icon={faSearch}
              onClick={() => getPaymentsPeriod(dataInicio, dataFim)}
            ></FontAwesomeIcon>
            <ConfigProvider locale={ptBR}>
              <RangePicker
                style={{ border: "1px solid", borderRadius: "4px" }}
                placeholder={["Data Inicial", "Data Final"]}
                onChange={(dates, dateStrings) => {
                  setDataInicio(dateStrings ? dateStrings[0] : null);
                  setDataFim(dateStrings ? dateStrings[1] : null);
                }}
              />
            </ConfigProvider>
          </div>
          <div style={{ display: "flex", gap: "20px" }}>
            {" "}
            <Button
              className="Admin_PagamentosSearch_header_editBtn"
              onClick={() => onRelatorioHandler()}
            >
              <img
                style={{ width: "15px", marginRight: "2px" }}
                src={notes}
                alt="notes"
              />
              <span>Relatório</span>
            </Button>
            <Button
              className="Admin_PagamentosSearch_header_editBtn"
              onClick={() => {
                navigate(`${links.CLIENTES_MAQUINAS_TROCAR}/${id}`, {
                  state: location.state,
                });
              }}
            >
              <FontAwesomeIcon
                icon={faArrowsRotate}
                style={{ marginRight: "5px" }}
              />
              <span>ID</span>
            </Button>
          </div>
        </Flex>
      </div>
      <div className="Admin_PagamentosSearch_body" style={{ width: "100%" }}>
        <div
          className="Admin_PagamentosSearch_content"
          style={{ width: "100%" }}
        >
          {isLargerThan800 && (
            <div
              className="Admin_PagamentosSearch_titleList_main"
              style={{ marginBottom: "10px" }}
            >
              <div className="Admin_PagamentosSearch_titleList">
                <div style={{ marginLeft: "20px", color: "white" }}>Total</div>
                <div className="Admin_PagamentosSearch_nbList">
                  {Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(total)}
                </div>
                <div style={{ marginLeft: "20px", color: "white" }}>
                  Estornos
                </div>
                <div className="Admin_PagamentosSearch_nbList">
                  {Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(estornos)}
                </div>
                <div style={{ marginLeft: "20px", color: "white" }}>
                  Espécie
                </div>
                <div className="Admin_PagamentosSearch_nbList">
                  {Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(cash)}
                </div>
                <div style={{ marginLeft: "20px", color: "white" }}>Débito</div>
                <div className="Admin_PagamentosSearch_nbList">
                  {Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(pagamentos.debito)}
                </div>
                <div style={{ marginLeft: "20px", color: "white" }}>
                  Crédito
                </div>
                <div className="Admin_PagamentosSearch_nbList">
                  {Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(pagamentos.credito)}
                </div>
                <div style={{ marginLeft: "20px", color: "white" }}>Pix</div>
                <div className="Admin_PagamentosSearch_nbList">
                  {Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(pagamentos.pix)}
                </div>

                <div style={{ marginLeft: "20px", color: "white" }}>Pulso</div>
                <div className="Admin_PagamentosSearch_nbList">
                  {maquinaInfos.pulso
                    ? Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(maquinaInfos.pulso)
                    : Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(0)}
                </div>
                <div style={{ marginLeft: "20px", color: "white" }}>
                  Store ID
                </div>
                <div className="Admin_PagamentosSearch_nbList">
                  {maquinaInfos.store_id}
                </div>
                <div style={{ marginLeft: "20px", color: "white" }}>
                  Estoque
                </div>
                <div className="Admin_PagamentosSearch_nbList">
                  {estoque ?? "-"}
                </div>
                {maquinaInfos.store_id && (
                  <>
                    {" "}
                    <div style={{ marginLeft: "20px", color: "white" }}>
                      Integração MP
                    </div>
                    <Link
                      target="_blank"
                      to={`//www.mercadopago.com.br/stores/detail?store_id=${maquinaInfos.store_id}`}
                      style={{
                        marginLeft: "10px",
                        backgroundColor: "white",
                        padding: "2px 5px",
                        fontSize: "16px",
                        borderRadius: "4px",
                        color: "black",
                      }}
                    >
                      Acessar MP
                    </Link>
                  </>
                )}
              </div>
            </div>
          )}

          {!isLargerThan800 && (
            <div
              className="Admin_PagamentosSearch_titleList"
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "20px",
                color: "white",
                width: "100%",
              }}
            >
              <div>
                <div>Total</div>
                <div className="Admin_PagamentosSearch_nbList">
                  {Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(total)}
                </div>
              </div>
              <div>
                {" "}
                <div>Estornos</div>
                <div className="Admin_PagamentosSearch_nbList">
                  {Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(estornos)}
                </div>
              </div>
              <div>
                <div>Espécie</div>
                <div className="Admin_PagamentosSearch_nbList">
                  {Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(cash)}
                </div>
              </div>

              <div>
                {" "}
                <div>Pulso</div>
                <div className="Admin_PagamentosSearch_nbList">
                  {maquinaInfos.pulso
                    ? Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(maquinaInfos.pulso)
                    : Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(0)}
                </div>
              </div>

              <div>
                {" "}
                <div>Débito</div>
                <div className="Admin_PagamentosSearch_nbList">
                  {Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(pagamentos.debito)}
                </div>
              </div>

              <div>
                {" "}
                <div>Crédito</div>
                <div className="Admin_PagamentosSearch_nbList">
                  {Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(pagamentos.credito)}
                </div>
              </div>

              <div>
                {" "}
                <div>Pix</div>
                <div className="Admin_PagamentosSearch_nbList">
                  {Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(pagamentos.pix)}
                </div>
              </div>

              <div>
                <div>Store ID</div>
                <div className="Admin_PagamentosSearch_nbList">
                  {maquinaInfos.store_id}
                </div>
              </div>

              <div>
                {" "}
                <div>Estoque</div>
                <div className="Admin_PagamentosSearch_nbList">
                  {estoque ?? "-"}
                </div>
              </div>
              {maquinaInfos.store_id && (
                <div>
                  <div>Integração MP</div>
                  <Link
                    target="_blank"
                    to={`//www.mercadopago.com.br/stores/detail?store_id=${maquinaInfos.store_id}`}
                    style={{
                      marginLeft: "10px",
                      backgroundColor: "white",
                      padding: "2px 5px",
                      fontSize: "16px",
                      borderRadius: "4px",
                      color: "black",
                    }}
                  >
                    Acessar MP
                  </Link>
                </div>
              )}
            </div>
          )}

          <div
            className="Admin_PagamentosSearch_description"
            style={{ color: "white", marginTop: "20px" }}
          >{`${maquinaInfos?.nome} - ${maquinaInfos?.descricao}`}</div>
          <Tabs>
            <TabList borderColor="#1F2630">
              <Tab
                color="white"
                _selected={{ borderColor: "white" }}
                fontSize="14px"
              >
                Todos{" "}
                <span
                  style={{
                    marginLeft: "10px",
                    padding: "5px 10px",
                    backgroundColor: "#01B0D1",
                    borderRadius: "8px",
                    fontSize: "12px",
                  }}
                >
                  {listCanals.length}
                </span>
              </Tab>
              <Tab
                color="white"
                _selected={{ borderColor: "white" }}
                fontSize="14px"
              >
                Recebidos{" "}
                <span
                  style={{
                    marginLeft: "10px",
                    padding: "5px 10px",
                    backgroundColor: "#21BD52",
                    borderRadius: "8px",
                    fontSize: "12px",
                  }}
                >
                  {pagamentosRecebidos.length}
                </span>
              </Tab>
              <Tab
                color="white"
                _selected={{ borderColor: "white" }}
                fontSize="14px"
              >
                Estornados
                <span
                  style={{
                    marginLeft: "10px",
                    padding: "5px 10px",
                    backgroundColor: "#F45031",
                    borderRadius: "8px",
                    fontSize: "12px",
                  }}
                >
                  {pagamentosEstornados.length}
                </span>
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <TableContainer minH="500px">
                  <Table size="sm">
                    <Thead>
                      <Tr>
                        <Th borderColor="#1F2630">Data</Th>
                        <Th borderColor="#1F2630">Forma de pagamento</Th>
                        <Th borderColor="#1F2630">Valor</Th>
                        <Th borderColor="#1F2630">Identificador MP</Th>
                        <Th borderColor="#1F2630">Estornado</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {listCanals.map(
                        ({
                          id,
                          data,
                          tipo,
                          valor,
                          mercadoPagoId,
                          estornado,
                        }) => {
                          return (
                            <Tr key={id}>
                              <Td color="white" borderColor="#1F2630">
                                {moment(data).format("DD/MM/YYYY HH:mm:ss")}
                              </Td>
                              <Td color="white" borderColor="#1F2630">
                                {tipo === "bank_transfer"
                                  ? "PIX"
                                  : tipo === "CASH"
                                  ? "Especie"
                                  : tipo === "debit_card"
                                  ? "Débito"
                                  : tipo === "credit_card"
                                  ? "Crédito"
                                  : tipo === "account_money"
                                  ? "PIX"
                                  : "-"}
                              </Td>
                              <Td color="white" borderColor="#1F2630">
                                {new Intl.NumberFormat("pt-BR", {
                                  style: "currency",
                                  currency: "BRL",
                                }).format(valor)}
                              </Td>
                              <Td color="white" borderColor="#1F2630">
                                {mercadoPagoId}
                              </Td>
                              <Td color="white" borderColor="#1F2630">
                                {estornado ? (
                                  <span style={{ color: "red" }}>
                                    Estornado
                                  </span>
                                ) : (
                                  <span style={{ color: "green" }}>
                                    Recebido
                                  </span>
                                )}
                              </Td>
                            </Tr>
                          );
                        }
                      )}
                    </Tbody>
                  </Table>
                </TableContainer>
              </TabPanel>
              <TabPanel>
                <TableContainer minH="500px">
                  <Table size="sm">
                    <Thead>
                      <Tr>
                        <Th borderColor="#1F2630">Data</Th>
                        <Th borderColor="#1F2630">Forma de pagamento</Th>
                        <Th borderColor="#1F2630">Valor</Th>
                        <Th borderColor="#1F2630">Identificador MP</Th>
                        <Th borderColor="#1F2630">Estornado</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {pagamentosRecebidos.map(
                        ({
                          id,
                          data,
                          tipo,
                          valor,
                          mercadoPagoId,
                          estornado,
                        }) => {
                          return (
                            <Tr key={id}>
                              <Td color="white" borderColor="#1F2630">
                                {moment(data).format("DD/MM/YYYY HH:mm:ss")}
                              </Td>
                              <Td color="white" borderColor="#1F2630">
                                {tipo === "bank_transfer"
                                  ? "PIX"
                                  : tipo === "CASH"
                                  ? "Especie"
                                  : tipo === "debit_card"
                                  ? "Débito"
                                  : tipo === "credit_card"
                                  ? "Crédito"
                                  : "-"}
                              </Td>
                              <Td color="white" borderColor="#1F2630">
                                {new Intl.NumberFormat("pt-BR", {
                                  style: "currency",
                                  currency: "BRL",
                                }).format(valor)}
                              </Td>
                              <Td color="white" borderColor="#1F2630">
                                {mercadoPagoId}
                              </Td>
                              <Td color="white" borderColor="#1F2630">
                                {estornado ? (
                                  <span style={{ color: "red" }}>
                                    Estornado
                                  </span>
                                ) : (
                                  <span style={{ color: "green" }}>
                                    Recebido
                                  </span>
                                )}
                              </Td>
                            </Tr>
                          );
                        }
                      )}
                    </Tbody>
                  </Table>
                </TableContainer>
              </TabPanel>
              <TabPanel>
                <TableContainer minH="500px">
                  <Table size="sm">
                    <Thead>
                      <Tr>
                        <Th borderColor="#1F2630">Data</Th>
                        <Th borderColor="#1F2630">Forma de pagamento</Th>
                        <Th borderColor="#1F2630">Valor</Th>
                        <Th borderColor="#1F2630">Identificador MP</Th>
                        <Th borderColor="#1F2630">Estornado</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {pagamentosEstornados.map(
                        ({
                          id,
                          data,
                          tipo,
                          valor,
                          mercadoPagoId,
                          estornado,
                        }) => {
                          return (
                            <Tr key={id}>
                              <Td color="white" borderColor="#1F2630">
                                {moment(data).format("DD/MM/YYYY HH:mm:ss")}
                              </Td>
                              <Td color="white" borderColor="#1F2630">
                                {tipo === "bank_transfer"
                                  ? "PIX"
                                  : tipo === "CASH"
                                  ? "Especie"
                                  : tipo === "debit_card"
                                  ? "Débito"
                                  : tipo === "credit_card"
                                  ? "Crédito"
                                  : "-"}
                              </Td>
                              <Td color="white" borderColor="#1F2630">
                                {new Intl.NumberFormat("pt-BR", {
                                  style: "currency",
                                  currency: "BRL",
                                }).format(valor)}
                              </Td>
                              <Td color="white" borderColor="#1F2630">
                                {mercadoPagoId}
                              </Td>
                              <Td color="white" borderColor="#1F2630">
                                {estornado ? (
                                  <span style={{ color: "red" }}>
                                    Estornado
                                  </span>
                                ) : (
                                  <span style={{ color: "green" }}>
                                    Recebido
                                  </span>
                                )}
                              </Td>
                            </Tr>
                          );
                        }
                      )}
                    </Tbody>
                  </Table>
                </TableContainer>
              </TabPanel>
            </TabPanels>
          </Tabs>
          {/* <Table
            columns={columns}
            dataSource={listCanals}
            pagination={false}
            loading={loadingTable}
            locale={{
              emptyText:
                searchText.trim() !== "" ? (
                  "-"
                ) : (
                  <div>Não foram encontrados resultados para sua pesquisa.</div>
                ),
            }}
          />*/}
        </div>
      </div>
    </div>
  );
};

export default AdminPagamentosSearch;
