import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../contexts/AuthContext";

export default function Lojas() {
  const { setDataUser, loading, authInfo, setNotiMessage } =
    useContext(AuthContext);

  const token = authInfo?.dataUser?.token;
  const { dataUser } = authInfo;

  const [modules, setModules] = useState([]);

  useEffect(() => {
    axios
      .get(
        dataUser.key === "ADMIN"
          ? `${process.env.REACT_APP_SERVIDOR}/maquinas-adm`
          : `${process.env.REACT_APP_SERVIDOR}/maquinas`,
        {
          headers: {
            "x-access-token": token,
            "content-type": "application/json",
          },
        }
      )
      .then((response) => {
        setModules(response.data);
      })
      .catch((error) => console.log("error", error));
  }, []);

  return (
    <Flex minH="90vh" flexDirection="column">
      <Text color="white" fontSize="24px">
        Lojas
      </Text>
      <TableContainer minH="500px">
        <Table size="sm">
          <Thead>
            <Tr>
              <Th borderColor="#1F2630">Loja</Th>
              <Th borderColor="#1F2630">Nome POS</Th>
              <Th borderColor="#1F2630">Conexão</Th>
            </Tr>
          </Thead>
          <Tbody>
            {modules.map(({ descricao, nome, status }) => (
              <Tr>
                <Td borderColor="#1F2630" color="white">
                  {descricao}
                </Td>
                <Td borderColor="#1F2630" color="white">
                  {nome}
                </Td>
                <Td borderColor="#1F2630">
                  {status === "OFFLINE" ? (
                    <div
                      style={{
                        width: "15px",
                        height: "15px",
                        borderRadius: "50%",
                        backgroundColor: "red",
                      }}
                    ></div>
                  ) : (
                    <div
                      style={{
                        width: "15px",
                        height: "15px",
                        borderRadius: "50%",
                        backgroundColor: "green",
                      }}
                    ></div>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  );
}
