import { MoneyCollectFilled } from "@ant-design/icons";
import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useMediaQuery,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { FaCoins } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthContext";
import axios from "axios";
import { DatePicker } from "antd";

export default function Telemetria() {
  const [totalFornecedores, setTotalFornecedores] = useState([]);
  const [todosPagamentos, setTodosPagamentos] = useState([]);
  const [pagamentos, setPagamentos] = useState({});
  const [machines, setMachines] = useState([]);
  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");
  const [filteredPagamentos, setFilteredPagamentos] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const { setDataUser, loading, authInfo, setNotiMessage } =
    useContext(AuthContext);
  const { dataUser } = authInfo;
  let navigate = useNavigate();
  const token = authInfo?.dataUser?.token;

  const dataData = () => {
    axios
      .get(
        dataUser.key === "ADMIN"
          ? `${process.env.REACT_APP_SERVIDOR}/maquinas-adm`
          : `${process.env.REACT_APP_SERVIDOR}/maquinas`,
        {
          headers: {
            "x-access-token": token,
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setTotalFornecedores(res.data);
        } else {
          throw new Error();
        }
      })
      .catch((err) => {
        if ([401, 403].includes(err.response.status)) {
          // setNotiMessage('A sua sessão expirou, para continuar faça login novamente.');
          setNotiMessage({
            type: "error",
            message:
              "A sua sessão expirou, para continuar faça login novamente.",
          });
          setDataUser(null);
        }
      });
  };

  useEffect(() => {
    dataData();
  }, []);

  useEffect(() => {
    const getRows = () => {
      const pagamentos = [];
      machines.forEach(({ nome, descricao, payments }) => {
        payments.pagamentos.forEach((pagamento) => {
          pagamentos.push({ cliente: nome, descricao, ...pagamento });
        });
      });

      setTodosPagamentos(pagamentos);
    };

    getRows();
  }, [machines]);

  useEffect(() => {
    const fetchMaquinas = async (maquinas) => {
      const promises = [];

      maquinas.forEach((maquina) => {
        const promise = axios
          .get(
            dataUser.key === "ADMIN"
              ? `${process.env.REACT_APP_SERVIDOR}/pagamentos-adm/${maquina.id}`
              : `${process.env.REACT_APP_SERVIDOR}/pagamentos/${maquina.id}`,
            {
              headers: {
                "x-access-token": token,
                "content-type": "application/json",
              },
            }
          )
          .then((response) => {
            return {
              nome: maquina.nome,
              descricao: maquina.descricao,
              produtosEntregues: maquina.produtosEntregues,
              payments: response.data,
            };
          })
          .catch((error) => {
            console.error(
              `Erro ao buscar a máquina ${maquina.nome} do cliente ${maquina.nome}:`,
              error
            );
            return null; // Retorna null em caso de erro
          });

        promises.push(promise);
      });

      const resultados = await Promise.all(promises);

      return resultados;
    };

    fetchMaquinas(totalFornecedores).then((resultados) => {
      setMachines(resultados);
    });
  }, [token, totalFornecedores]);

  useEffect(() => {
    const calcularPagamentos = () => {
      const pagamentosCredito = calcularTotalPorTipo(
        "credit_card",
        pagamentosAtual
      );
      const pagamentosDebito = calcularTotalPorTipo(
        "debit_card",
        pagamentosAtual
      );
      const pagamentosPix = pagamentosAtual
        .filter(
          (item) =>
            item.tipo === "bank_transfer" || item.tipo === "account_money"
        )
        .reduce((acc, curr) => acc + Number(curr.valor), 0);
      const pagamentosEspecie = calcularTotalPorTipo("CASH", pagamentosAtual);

      setPagamentos((prev) => ({
        ...prev,
        credito: pagamentosCredito,
        debito: pagamentosDebito,
        pix: pagamentosPix,
        especie: pagamentosEspecie,
      }));
    };

    const pagamentosNormal = todosPagamentos.filter(
      ({ estornado }) => !estornado
    );

    const pagamentosAtual =
      startDate && endDate ? filteredPagamentos : todosPagamentos;

    const calcularTotalPorTipo = (tipo, pagamentos) => {
      return pagamentos
        .filter((item) => item.tipo === tipo)
        .reduce((acc, curr) => acc + Number(curr.valor), 0);
    };

    calcularPagamentos();
  }, [totalFornecedores, pagamentos, todosPagamentos, filteredPagamentos]);

  useEffect(() => {
    if (!startDate || !endDate) {
      setFilteredPagamentos(todosPagamentos); // Sem filtro
      return;
    }

    const filtered = todosPagamentos.filter(({ data }) => {
      const pagamentoDate = new Date(data);
      return (
        pagamentoDate >= new Date(startDate) &&
        pagamentoDate <= new Date(endDate)
      );
    });

    setFilteredPagamentos(filtered);
  }, [startDate, endDate, todosPagamentos]);

  const totalProdutosEntregues = machines.reduce((acc, curr) => {
    return acc + curr.produtosEntregues;
  }, 0);

  return (
    <Flex flexDirection="column" minH="100vh" width="100%">
      <Text color="white" fontSize="24px">
        Telemetria
      </Text>
      <Flex width="100%">
        <div
          style={{
            display: "grid",
            gridTemplateColumns: isLargerThan800
              ? "repeat(4,1fr)"
              : "repeat(1,1fr)",
            width: "100%",
            gridGap: isLargerThan800 ? "20px" : "0px",
          }}
        >
          <Flex
            padding={isLargerThan800 ? "10px 20px 0px 20px" : "0px 20px"}
            borderRadius={isLargerThan800 ? "20px" : "20px 20px 0px 0px"}
            backgroundColor="#1F2630"
            flexDirection="column"
            height={!isLargerThan800 && "100px"}
          >
            <Flex
              width="100%"
              alignItems="center"
              gap="10px"
              marginTop={!isLargerThan800 && "15px"}
            >
              <FaCoins style={{ marginTop: "-20px", color: "white" }} />
              <Text color="white" fontSize="18px">
                Dinheiro em Espécie
              </Text>
            </Flex>
            <Text
              color="white"
              fontSize="24px"
              marginTop="-30px"
              fontWeight="700"
            >
              {Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(pagamentos?.especie)}
            </Text>
          </Flex>
          <Flex
            padding={isLargerThan800 ? "10px 20px 0px 20px" : "0px 20px"}
            borderRadius={isLargerThan800 && "20px"}
            backgroundColor="#1F2630"
            flexDirection="column"
            borderTop={!isLargerThan800 && "1px dashed grey"}
            height={!isLargerThan800 && "100px"}
          >
            <Flex
              width="100%"
              alignItems="center"
              gap="10px"
              marginTop={!isLargerThan800 && "15px"}
            >
              <FaCoins style={{ marginTop: "-20px", color: "white" }} />
              <Text color="white" fontSize="18px">
                PIX
              </Text>
            </Flex>
            <Text
              color="white"
              fontSize="24px"
              marginTop="-30px"
              fontWeight="700"
            >
              {Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(pagamentos?.pix)}
            </Text>
          </Flex>
          <Flex
            padding={isLargerThan800 ? "10px 20px 0px 20px" : "0px 20px"}
            borderRadius={isLargerThan800 && "20px"}
            backgroundColor="#1F2630"
            flexDirection="column"
            borderTop={!isLargerThan800 && "1px dashed grey"}
            height={!isLargerThan800 && "100px"}
          >
            <Flex
              width="100%"
              alignItems="center"
              gap="10px"
              marginTop={!isLargerThan800 && "15px"}
            >
              <FaCoins style={{ marginTop: "-20px", color: "white" }} />
              <Text color="white" fontSize="18px">
                Débito
              </Text>
            </Flex>
            <Text
              color="white"
              fontSize="24px"
              marginTop="-30px"
              fontWeight="700"
            >
              {Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(pagamentos?.debito)}
            </Text>
          </Flex>
          <Flex
            padding={isLargerThan800 ? "10px 20px 0px 20px" : "0px 20px"}
            borderRadius={isLargerThan800 && "20px"}
            backgroundColor="#1F2630"
            flexDirection="column"
            borderTop={!isLargerThan800 && "1px dashed grey"}
            height={!isLargerThan800 && "100px"}
          >
            <Flex
              width="100%"
              alignItems="center"
              gap="10px"
              marginTop={!isLargerThan800 && "15px"}
            >
              <FaCoins style={{ marginTop: "-20px", color: "white" }} />
              <Text color="white" fontSize="18px">
                Crédito
              </Text>
            </Flex>
            <Text
              color="white"
              fontSize="24px"
              marginTop="-30px"
              fontWeight="700"
            >
              {Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(pagamentos?.credito)}
            </Text>
          </Flex>
          <Flex
            padding={isLargerThan800 ? "10px 20px 0px 20px" : "0px 20px"}
            borderRadius={isLargerThan800 && "20px"}
            backgroundColor="#1F2630"
            flexDirection="column"
            borderTop={!isLargerThan800 && "1px dashed grey"}
            height={!isLargerThan800 && "100px"}
          >
            <Flex
              width="100%"
              alignItems="center"
              gap="10px"
              marginTop={!isLargerThan800 && "15px"}
            >
              <FaCoins style={{ marginTop: "-20px", color: "white" }} />
              <Text color="white" fontSize="18px">
                Produtos entregues
              </Text>
            </Flex>
            <Text
              color="white"
              fontSize="24px"
              marginTop="-30px"
              fontWeight="700"
            >
              {totalProdutosEntregues}
            </Text>
          </Flex>
          <Flex
            padding={isLargerThan800 ? "10px 20px 0px 20px" : "0px 20px"}
            borderRadius={isLargerThan800 ? "20px" : "0px 0px 20px 20px"}
            backgroundColor="#1F2630"
            flexDirection="column"
            justifyContent={!isLargerThan800 && "center"}
            borderTop={!isLargerThan800 && "1px dashed grey"}
            height={!isLargerThan800 && "100px"}
          >
            <Flex
              width="100%"
              alignItems="center"
              gap="10px"
              marginTop={!isLargerThan800 && "20px"}
            >
              <FaCoins style={{ marginTop: "-20px", color: "white" }} />
              <Text color="white" fontSize="18px">
                Valor total
              </Text>
            </Flex>
            <Text
              color="white"
              fontSize="24px"
              marginTop="-30px"
              fontWeight="700"
            >
              {Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(
                pagamentos?.pix +
                  pagamentos?.debito +
                  pagamentos?.credito +
                  pagamentos?.especie
              )}
            </Text>
          </Flex>
        </div>
      </Flex>
      <Flex width="100%" marginTop="20px" flexDirection="column">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: !isLargerThan800 && "center",
          }}
        >
          <label
            htmlFor="data-inicial"
            style={{ marginBottom: "8px", color: "white" }}
          >
            Data Inicial
          </label>
          <DatePicker
            id="data-inicial"
            style={{
              border: "1px solid #1F2630",
              borderRadius: "4px",
              width: "100%",
              maxWidth: isLargerThan800 && "250px",
              backgroundColor: "transparent",
            }}
            showTime={{ format: "HH:mm" }}
            format="YYYY-MM-DD HH:mm"
            placeholder="Selecione a data e hora"
            onChange={(date, dateString) => {
              setStartDate(dateString || null);
            }}
            getPopupContainer={(trigger) => trigger.parentElement}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "8px",
            alignItems: !isLargerThan800 && "center",
          }}
        >
          <label
            htmlFor="data-final"
            style={{ marginBottom: "8px", color: "white" }}
          >
            Data Final
          </label>
          <DatePicker
            id="data-final"
            style={{
              border: "1px solid #1F2630",
              borderRadius: "4px",
              width: "100%",
              maxWidth: isLargerThan800 && "250px",
              backgroundColor: "transparent",
              color: "white",
            }}
            showTime={{ format: "HH:mm" }}
            format="YYYY-MM-DD HH:mm"
            placeholder="Selecione a data e hora"
            onChange={(date, dateString) => setEndDate(dateString || null)}
            getPopupContainer={(trigger) => trigger.parentElement}
          />
        </div>
        <TableContainer width="100%">
          <Table size="sm">
            <Thead>
              <Tr>
                <Th borderColor="#1F2630">Produtos Entregues</Th>
                <Th borderColor="#1F2630">Pix</Th>
                <Th borderColor="#1F2630">Débito</Th>
                <Th borderColor="#1F2630">Crédito</Th>
                <Th borderColor="#1F2630">Espécie</Th>
                <Th borderColor="#1F2630">Valor Total</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td borderColor="#1F2630" color="white">
                  {totalProdutosEntregues}
                </Td>
                <Td borderColor="#1F2630" color="white">
                  {Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(pagamentos?.pix)}
                </Td>
                <Td borderColor="#1F2630" color="white">
                  {" "}
                  {Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(pagamentos?.debito)}
                </Td>
                <Td borderColor="#1F2630" color="white">
                  {" "}
                  {Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(pagamentos?.credito)}
                </Td>
                <Td borderColor="#1F2630" color="white">
                  {" "}
                  {Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(pagamentos?.especie)}
                </Td>
                <Td borderColor="#1F2630" color="white">
                  {" "}
                  {Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(
                    pagamentos?.pix +
                      pagamentos?.debito +
                      pagamentos?.credito +
                      pagamentos?.especie
                  )}
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>
    </Flex>
  );
}
